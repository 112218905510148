<template>
  <div>
    <PageTitle />

    <b-container class="bv-example-row">
      <b-row>
        <b-col
          offset-md="2"
          offset-sm="2"
        />

      </b-row>
    </b-container>

    <How />

    <!-- Why Choose Section Start -->
    <div class="choose-style-two choose-style-three pt-4 pb-4">
      <div class="container">
        <div class="section-title text-center">
          <h2>eBDFRET vous propose des services d'offre de fret
            intéressants  </h2>

        </div>

        <div class="row align-items-center">
          <div class="col-lg-3">
            <div class="row">
              <div
                class="col-lg-12 col-sm-4 wow fadeInUp"
                data-wow-duration="1s"
              >
                <div class="choose-card">
                  <i class="icofont-fast-delivery" />
                  <h3>Trouvez du fret proche de vous quand vous avez besoin</h3>
                </div>
              </div>
              <div
                class="col-lg-12 col-sm-4 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div class="choose-card">
                  <i class="icofont-ssl-security" />
                  <h3>Respect des  mesures de sécurisation des produits  et de leurs conformités  aux codes de douane</h3>
                </div>
              </div>
              <div
                class="col-lg-12 col-sm-4 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".4s"
              >
                <div class="choose-card">
                  <i class="icofont-diamond" />
                  <h3>Améliorez votre qualité de service en traçant vos missions gratuitement</h3>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="why-choose-image">
              <img
                src="@/assets/images/car1.jpg"
                width="500px"
                alt="why Choose image"
              >
            </div>
          </div>

          <div class="col-lg-3">
            <div class="row">
              <div
                class="col-lg-12 col-sm-4 wow fadeInUp"
                data-wow-duration="1s"
              >
                <div class="choose-card">
                  <i class="icofont-ui-browser" />
                  <h3>Trouver des offre et proposer des devis en  un clic</h3>
                </div>
              </div>
              <div
                class="col-lg-12 col-sm-4 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div class="choose-card">
                  <i class="icofont-live-support" />
                  <h3>Assistance 24/7</h3>
                </div>
              </div>
              <div
                class="col-lg-12 col-sm-4 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".4s"
              >
                <div class="choose-card">
                  <i class="icofont-like" />
                  <h3>eBDFRET vous aide à Optimisez vos chargements et augmentez vos marges</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Why Choose Section End -->

  </div>

</template>

<script>
import PageTitle from '@/layouts/SiteLayout/espace_transporteur/PageTitle.vue'
import How from '@/layouts/SiteLayout/espace_transporteur/How.vue'
import {
  BContainer,
  BCol,
  BRow,
} from 'bootstrap-vue'

export default {
  components: {
    PageTitle,
    How,
    BRow,
    BCol,
    BContainer,
  },
}
</script>
