<template>
  <div>
    <!-- Page Title Start -->
    <div class="page-title title-bg-10">
      <div class="container">
        <div class="title-text text-center">
          <h2>Pourquoi nous choisir eBDFRET</h2>

          <h2 style="font-size:30px;">
            Les dernières offres de frets
          </h2>
        </div>

      </div>

      <FretTables />
    </div>
    <!-- Page Title End -->

  </div>

</template>
<script>

import FretTables from './FretTables.vue'

export default {
  components: { FretTables },
}
</script>
